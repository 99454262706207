<script>
import axios from "@/axios/axios.js";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import Header from "@/components/Header.vue";

export default {
    components: { Header },
    data() {
        return {
            events: [
                {
                    id: 1,
                    title: "Concert de Jazz",
                    date: "12 Août 2024",
                    location: "Paris",
                    price: "50€",
                    imageUrl: "https://picsum.photos/400/200?random=1"
                },
                {
                    id: 2,
                    title: "Conférence Tech",
                    date: "25 Septembre 2024",
                    location: "Londres",
                    price: "100€",
                    imageUrl: "https://picsum.photos/400/200?random=2"
                },
                {
                    id: 3,
                    title: "Festival de Cinéma",
                    date: "3 Octobre 2024",
                    location: "Cannes",
                    price: "70€",
                    imageUrl: "https://picsum.photos/400/200?random=3"
                },
                // Ajoutez plus d'événements ici
            ],
        };
    },
};
</script>

<template>
    <main class="main">
        <Header /><br /><br />

        <div class="container mt-4">
            <div class="row">
                <div v-for="event in events" :key="event.id" class="col-md-4 mb-4">
                    <div class="card h-100">
                        <img :src="event.imageUrl" class="card-img-top" alt="Image de l'événement">
                        <div class="card-body">
                            <h5 class="card-title">{{ event.title }}</h5>
                            <p class="card-text"><strong>Date :</strong> {{ event.date }}</p>
                            <p class="card-text"><strong>Lieu :</strong> {{ event.location }}</p>
                            <p class="card-text"><strong>Prix :</strong> {{ event.price }}</p>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-primary">Acheter un ticket</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <footer class="py-4 mt-auto" style="background-color: #000; color: white;">
        <div class="container px-5">
            <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                <div class="col-auto">
                    <div class="small m-0">Mougni 2024</div>
                </div>
                <div class="col-auto">
                    <div class="small m-0">Confidentialité</div>
                </div>
                <div class="col-auto">
                    <div class="small m-0">Terms et conditions</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
.card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-body {
    background-color: #f8f9fa;
}

.card-footer {
    background-color: #e9ecef;
    text-align: center;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}
</style>
