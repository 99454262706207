<template>
<div>
    <HeaderDash />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Recherche</h5>
                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <label for="name">Nom du Badge</label>
                                    <input v-model="filters.name" @input="fetchEventBadges" type="text" id="name" class="form-control">
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-12 d-flex justify-content-center d-md-none">
                                    <button type="button" class="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#soumettreBadgeModal">
                                        <i class="fas fa-plus"></i> Soumettre mon Badge
                                    </button>
                                </div>
                                <div class="col-12 d-none d-md-flex justify-content-start">
                                    <button type="button" class="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#soumettreBadgeModal">
                                        <i class="fas fa-plus"></i> Soumettre mon Badge
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="soumettreBadgeModal" tabindex="-1" aria-labelledby="soumettreBadgeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="soumettreBadgeModalLabel">Soumettre un Badge</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="submitBadge">
                                    <div class="mb-3">
                                        <label for="name" class="form-label">Nom du badge/Évenement</label>
                                        <input type="text" class="form-control" id="name" v-model="name" maxlength="23" required>
                                    </div>

                                    <div class="mb-3">
                                        <label for="description" class="form-label">Description</label>
                                        <textarea class="form-control" id="description" v-model="description"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="start_date" class="form-label">Date de début</label>
                                        <input type="date" class="form-control" id="start_date" v-model="start_date" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="end_date" class="form-label">Date de fin</label>
                                        <input type="date" class="form-control" id="end_date" v-model="end_date" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="location" class="form-label">Emplacement</label>
                                        <input type="text" class="form-control" id="location" v-model="location">
                                    </div>
                                    <div class="mb-3">
                                        <label for="option" class="form-label">Option</label>
                                        <select class="form-control" id="option" v-model="option" required>
                                            <option value="">Selectionnez une option</option>
                                            <option value="Freemium">Freemium (15 badges)</option>
                                            <option value="Standard">Standard (125 badges)</option>
                                            <option value="Premium">Premium (badges illimités)</option>
                                        </select>
                                    </div>
                                    <div class="mb-3">
                                        <label for="badge" class="form-label">Badge J'y serai (Max: 5Mo)</label>
                                        <input type="file" class="form-control" id="badge" accept="image/*" @change="handleBadgeChange">
                                    </div>

                                    <button type="submit" class="btn btn-primary" ref="payBtn">
                                        <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span v-if="!isSubmitting">Soumettre</span>
                                        <span v-if="isSubmitting"> En cours...</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Mes Badges</h5>
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #000;">
                                        <tr>
                                            <th class="d-none d-md-table-cell" scope="col" style="color: white;">N°
                                            </th>
                                            <th scope="col" style="color: white;">Badge</th>
                                            <th scope="col" style="color: white;">Option</th>
                                            <th scope="col" style="color: white;">Statut</th>
                                            <th scope="col" style="color: white;">Vues</th>
                                            <th scope="col" style="color: white;">Téléchargements</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="eventBadges.data.length > 0">
                                        <tr v-for="(badge, index) in eventBadges.data" :key="index">
                                            <td class="d-none d-md-table-cell">{{ index + 1 }}</td>
                                            <td>{{ badge.name }}</td>
                                            <td>{{ badge.option }}</td>
                                            <td>
                                                <span v-if="badge.statut === 'En cours'" class="badge text-bg-warning">
                                                    {{ badge.statut }}
                                                </span>
                                                <span v-else-if="badge.statut === 'Validé'" class="badge text-bg-success">
                                                    {{ badge.statut }}
                                                </span>
                                                <span v-else-if="badge.statut === 'Echec'" class="badge text-bg-danger">
                                                    {{ badge.statut }}
                                                </span>
                                            </td>
                                            <td>{{ badge.views }}</td>
                                            <td>{{ badge.downloads }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="12" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                    <span>Pas disponible.</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item" :class="{ disabled: !eventBadges.prev_page_url }">
                                            <a class="page-link" href="#" @click.prevent="fetchEventBadges(eventBadges.current_page - 1)">Précédent</a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: !eventBadges.next_page_url }">
                                            <a class="page-link" href="#" @click.prevent="fetchEventBadges(eventBadges.current_page + 1)">Suivant</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modal pour informer l'organisateur -->
                <div class="modal fade" id="informOrganisateurModal" tabindex="-1" aria-labelledby="informOrganisateurModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header d-flex justify-content-center position-relative">
                                <button type="button" class="btn-close position-absolute top-0 end-0 m-3" data-bs-dismiss="modal" aria-label="Close"></button>
                                <lord-icon src="https://cdn.lordicon.com/cqofjexf.json" trigger="in" delay="1000" style="width:250px;height:250px">
                                </lord-icon>
                            </div>
                            <div class="modal-body text-center">
                                <p>Un e-mail vous a été envoyé. Nous vous souhaitons une excellente promotion de
                                    votre événement ! <i class="fas fa-smile-wink"></i></p>

                            </div>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    </div>
</div>
</template>

<script>
import HeaderDash from "@/components/HeaderDash.vue";
import SideBar from "@/components/SideBar.vue";
import axios from "@/axios/axios.js";
import Swal from 'sweetalert2';
import {
    FedaPayPublicKey
} from '@/fedapay';

export default {
    components: {
        HeaderDash,
        SideBar
    },
    data() {
        return {
            name: '',
            description: '',
            start_date: '',
            end_date: '',
            location: '',
            badge: null,
            option: '',
            isSubmitting: false,
            prices: {
                Standard: 3500,
                Premium: 5000
            },
            eventBadges: {
                data: []
            },

            filters: {
                option: '',
                statut: '',
                name: ''
            }
        };
    },

    methods: {
        handleBadgeChange(event) {
            this.badge = event.target.files[0];
        },

        initializeFedaPay(price, title) {
            const userData = JSON.parse(sessionStorage.getItem("userData"));
            const email = userData.email;
            const lastname = userData.eventowner.last_name;
            const firstname = userData.eventowner.first_name;

            if (!price || !title) {
                console.error('Price or title is missing for FedaPay initialization');
                return;
            }

            FedaPay.init({
                public_key: FedaPayPublicKey,
                transaction: {
                    amount: price,
                    description: title
                },
                customer: {
                    email: email,
                    lastname: lastname,
                    firstname: firstname
                },
                onComplete: ({
                    reason,
                    transaction
                }) => {
                    if (reason === FedaPay.CHECKOUT_COMPLETED) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Paiement effectué',
                            text: 'Le paiement a été effectué avec succès!',
                            showConfirmButton: false,
                            timer: 1500
                        }).then((result) => {
                            this.submitBadgeForm();
                        });
                    } else if (reason === FedaPay.DIALOG_DISMISSED) {
                        console.log('La boîte de dialogue a été fermée par l\'utilisateur.');
                        this.isSubmitting = false;
                    }
                }
            }).open();
        },

        submitBadgeForm() {
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const user_id = userData.id;
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('description', this.description);
            formData.append('start_date', this.start_date);
            formData.append('end_date', this.end_date);
            formData.append('location', this.location);
            formData.append('option', this.option);
            formData.append('badge', this.badge);
            formData.append('user_id', user_id);

            axios.post('/store-badge', formData)
                .then(response => {
                    console.log(response.data);
                    Swal.fire({
                        icon: 'success',
                        title: 'Badge soumis avec succès!',
                        text: 'Votre badge a été soumis avec succès.',
                        showConfirmButton: false,
                        timer: 2000
                    }).then(() => {
                        $('#informOrganisateurModal').modal('show');
                    });
                    this.resetForm();
                    $('#soumettreBadgeModal').modal('hide');
                })
                .catch(error => {
                    console.error(error);

                    // Vérifier si l'erreur provient de la limite de l'option Freemium
                    if (error.response && error.response.data.message) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: error.response.data.message,
                            showConfirmButton: true,
                        });
                    } else {
                        // Autres erreurs
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: 'Une erreur est survenue. Veuillez réessayer.',
                            showConfirmButton: true,
                        });
                    }
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },

        submitBadge() {
            this.isSubmitting = true;

            if (this.option === 'Standard' || this.option === 'Premium') {
                const price = this.prices[this.option];
                const title = `Achat de badge ${this.option}`;
                this.initializeFedaPay(price, title);
            } else {
                this.submitBadgeForm();
            }
        },

        resetForm() {
            this.name = '';
            this.description = '';
            this.start_date = '';
            this.end_date = '';
            this.location = '';
            this.option = '';
            this.badge = null;
        },

        fetchEventBadges(page = 1) {
            const params = {
                page,
                ...this.filters
            };

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const userId = userData.id;
            axios.get(`/users-events-badges/${userId}`, {
                    params
                })
                .then(response => {
                    this.eventBadges = response.data;
                })
                .catch(error => {
                    console.error("There was an error fetching the event badges:", error);
                });
        }
    },
    mounted() {
        this.fetchEventBadges();
    }
}
</script>

<style>
/* Vous pouvez ajouter des styles personnalisés pour le spinner ici */
</style>
