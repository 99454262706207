<template>
  <main class="main">
    <div class="container-fluid background-image">
      <div class="row justify-content-center align-items-center min-vh-100 py-5">
        <div class="col-md-10 col-lg-6 col-xl-5">
          <div class="shadow p-3 mb-5 bg-body rounded">
            <div class="text-center mb-7">
              <!-- Icône de groupe de personnes -->
              <a href="/">
                <img src="/images/logomougni.png" alt="Icône de connexion" style="width: 3rem; height: 3rem;">
              </a>
              <h3 class="text-1000 mt-2">S'INSCRIRE</h3>
            </div>
            <form @submit.prevent="register">
              <div class="mb-3 text-start">
                <label class="form-label" for="first_name">Nom</label>
                <input class="form-control form-icon-input" v-model="form.first_name" id="first_name" type="text"
                  placeholder="Votre nom" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="last_name">Prénom</label>
                <input class="form-control form-icon-input" v-model="form.last_name" id="last_name" type="text"
                  placeholder="Votre prénom" required />
              </div>

              <label class="form-label" for="phone">Téléphone</label>
              <div class="input-group mb-3">
                <span class="input-group-text">+{{ callingCode }}</span>
                <input class="form-control form-icon-input" v-model="form.phone" id="phone" type="text"
                  placeholder="Entrez votre numéro" required pattern="\d*" inputmode="numeric"
                  @input="validatePhoneInput" />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="whatsapp">Numéro WhatsApp</label>
                <div class="input-group mb-3">
                  <span class="input-group-text">+{{ callingCode }}</span>
                  <input class="form-control form-icon-input" v-model="form.whatsapp" id="whatsapp" type="text"
                    placeholder="Numéro WhatsApp" pattern="\d*" inputmode="numeric" @input="validateWhatsAppInput" />
                </div>
              </div>

              <div class="mb-3 text-start">
                <label class="form-label" for="email">Email</label>
                <input class="form-control form-icon-input" v-model="form.email" id="email" type="email"
                  placeholder="mougniboss@gmail.com" required />
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="password">Mot de passe</label>
                <div class="form-icon-container position-relative">
                  <input class="form-control form-icon-input" v-model="form.password"
                    :type="passwordVisible ? 'text' : 'password'" placeholder="Mot de passe" id="password" required />
                  <button type="button"
                    class="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    @click="togglePasswordVisibility('password')" style="z-index: 2;">
                    <i :class="passwordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                  </button>
                </div>
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="password_confirmation">Confirmez</label>
                <div class="form-icon-container position-relative">
                  <input class="form-control form-icon-input" v-model="form.password_confirmation"
                    :type="passwordVisible ? 'text' : 'password'" placeholder="Confirmation" id="password_confirmation"
                    required />
                  <button type="button"
                    class="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    @click="togglePasswordVisibility('password_confirmation')" style="z-index: 2;">
                    <i :class="passwordVisible ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                  </button>
                </div>
              </div>
              <button class="btn btn-secondary w-100 mb-3" style="background-color: #ffc107;" type="submit"
                :disabled="isLoading">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>S'inscrire</span>
              </button>
            </form>
            <div class="text-center mt-3">
              <a class="fs--1 fw-semi-bold text-black" href="/login">
                Déjà inscrit ? Connectez-vous
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";

export default {
  components: { Header },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        phone: '',
        whatsapp: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      isLoading: false,
      callingCode: '', 
      passwordVisible: false 
    };
  },
  methods: {
    async fetchCallingCode() {
      try {
        const response = await axios.get('https://ipwho.is/');
        this.callingCode = response.data.calling_code || ''; 
      } catch (error) {
        console.error('Failed to fetch calling code:', error);
        this.callingCode = '';
      }
    },
    async register() {
      this.isLoading = true;
      const fullPhoneNumber = `${this.callingCode}${this.form.phone}`;
      const fullWhatsAppNumber = `${this.callingCode}${this.form.whatsapp}`;

      try {
        const registrationForm = {
          ...this.form,
          phone: fullPhoneNumber, 
          whatsapp: fullWhatsAppNumber
        };

        const response = await axios.post('/register', registrationForm);
        if (response.data.status) {
          // Redirige simplement vers la page de connexion sans afficher de notification
          this.$router.push('/login');
        } else {
          new Noty({
            type: 'error',
            layout: 'bottomRight', 
            text: 'Échec de l\'inscription',
            timeout: 3000,
            progressBar: true
          }).show();
        }
      } catch (error) {
        new Noty({
          type: 'error',
          layout: 'bottomRight', 
          text: 'Erreur lors de l\'inscription',
          timeout: 3000,
          progressBar: true
        }).show();
      } finally {
        this.isLoading = false;
      }
    },
    togglePasswordVisibility(inputId) {
      this.passwordVisible = !this.passwordVisible;
      const input = document.getElementById(inputId);
      input.type = this.passwordVisible ? 'text' : 'password';
    },

    validatePhoneInput(event) {
      const input = event.target.value;
      if (/^\d*$/.test(input)) {
        this.form.phone = input;
      } else {
        this.form.phone = this.form.phone.replace(/\D/g, ''); 
      }
    },

    validateWhatsAppInput(event) {
      const input = event.target.value;
      if (/^\d*$/.test(input)) {
        this.form.whatsapp = input;
      } else {
        this.form.whatsapp = this.form.whatsapp.replace(/\D/g, ''); 
      }
    }
  },
  mounted() {
    this.fetchCallingCode(); 
  }
};
</script>


<style scoped>
.background-image {
  background-image: url('/public/images/background.webp');
  /* Remplacez par le chemin de votre image */
  background-size: cover;
  background-position: center;
}
</style>
