<template>
    <nav id="sidebarMenu" class="col-md-2 col-lg-2 d-md-block sidebar collapse">
        <div class="position-sticky py-4 px-3 sidebar-sticky">
            <ul class="nav flex-column h-100">
                <!-- ACCUEIL -->
                <li v-if="userRoleId === 2" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'accueil' }"
                        :to="{ path: '/organisateur/apercu' }" @click="selectMenu('accueil')">
                        <i class="bi-house-fill me-2"></i>
                        <b> ACCUEIL </b>
                    </router-link>
                </li>

                <!-- MES BADGES -->
                <li v-if="userRoleId === 2" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'badges' }"
                        :to="{ path: '/organisateur/mes-badges' }" @click="selectMenu('badges')">
                        <i class="bi-collection me-2"></i>
                        <b> MES BADGES </b>
                    </router-link>
                </li>

                <!-- SMS WHATSAPP -->
                <li v-if="userRoleId === 1" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'whatsappsms' }"
                        :to="{ path: '/organisateur/whatsappsms' }" @click="selectMenu('whatsappsms')">
                        <i class="bi-chat-fill me-2"></i>
                        <b> SMS WHATSAPP </b>
                    </router-link>
                </li>

                <!-- APERCU (admin seulement) -->
                <li v-if="userRoleId === 1" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'apercu' }"
                        :to="{ path: '/admin/apercu' }" @click="selectMenu('apercu')">
                        <i class="bi-people-fill me-2"></i>
                        <b> APERCU </b>
                    </router-link>
                </li>

                <!-- BLOG (affiché uniquement si role_id = 1) -->
                <li v-if="userRoleId === 1" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'blog' }"
                        :to="{ path: '/admin/blog' }" @click="selectMenu('blog')">
                        <i class="bi-journal-text me-2"></i>
                        <b> BLOG </b>
                    </router-link>
                </li>

                <!-- AJOUTER UN ÉVÉNEMENT (admin seulement) -->
                <li v-if="userRoleId === 1" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'modifier_badge' }"
                        :to="{ path: '/admin/modifier-badge' }" @click="selectMenu('modifier_badge')">
                        <i class="bi-calendar-plus me-2"></i>
                        <b> MODIFIER BADGES </b>
                    </router-link>
                </li>

                <!-- GESTION DES UTILISATEURS (admin seulement) -->
                <li v-if="userRoleId === 1" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'gestion_utilisateurs' }"
                        :to="{ path: '/admin/gestion-utilisateurs' }" @click="selectMenu('gestion_utilisateurs')">
                        <i class="bi-people-fill me-2"></i>
                        <b> GERER UTILISATEURS </b>
                    </router-link>
                </li>


                <!-- PARAMÈTRES -->
                <li v-if="userRoleId === 2" class="nav-item">
                    <router-link class="nav-link" :class="{ active: selectedMenu === 'parametres' }"
                        :to="{ path: '/organisateur/parametre' }" @click="selectMenu('parametres')">
                        <i class="bi-gear me-2"></i>
                        <b> PARAMETRES </b>
                    </router-link>
                </li>

                <!-- DÉCONNEXION -->
                <li class="nav-item">
                    <a class="nav-link" @click.prevent="logoutUser">
                        <i class="bi-box-arrow-left me-2"></i><b>DÉCONNEXION</b>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            selectedMenu: '',
            userRoleId: null, // Initialiser à null
        };
    },
    methods: {
        selectMenu(menu) {
            this.selectedMenu = menu;
        },
        logoutUser() {
            try {
                sessionStorage.clear();
                Swal.fire({
                    icon: 'success',
                    title: 'Déconnexion réussie',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000
                });

                this.$router.push('/');
            } catch (error) {
                console.error('Erreur lors de la déconnexion:', error);
            }
        },
    },
    watch: {
        '$route'(to) {
            const routeMap = {
                '/organisateur/apercu': 'accueil',
                '/organisateur/mes-badges': 'badges',
                '/organisateur/whatsappsms': 'whatsappsms',
                '/admin/blog': 'blog',
                '/admin/modifier-badge': 'modifier_badge',
                '/admin/apercu': 'apercu',
                '/organisateur/parametre': 'parametres',
            };
            this.selectedMenu = routeMap[to.path] || '';
        }
    },
    created() {
        const routeMap = {
            '/organisateur/apercu': 'accueil',
            '/organisateur/mes-badges': 'badges',
            '/organisateur/whatsappsms': 'whatsappsms',
            '/admin/blog': 'blog',
            '/admin/modifier-badge': 'modifier_badge',
            '/admin/apercu': 'apercu',
            '/organisateur/parametre': 'parametres',
        };
        this.selectedMenu = routeMap[this.$route.path] || '';

        // Récupérer userData de sessionStorage et assigner role_id à userRoleId
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (userData) {
            this.userRoleId = userData.role_id;
        }
    }
};
</script>

<style>
#sidebarMenu .nav-link.active,
#sidebarMenu .nav-link:hover {
    color: #fff;
}
</style>
