<template>
  <div class="container-fluid background-image">
    <div class="row justify-content-center align-items-center min-vh-100 py-5">
      <div class="col-md-10 col-lg-6 col-xl-5">
        <div class="shadow p-3 mb-5 bg-body rounded">
          <div class="text-center mb-7">
            <!-- Icône de connexion -->
            <a href="/">
              <img src="/images/logomougni.png" alt="Icône de connexion" style="width: 3rem; height: 3rem;">
            </a>

            <h3 class="text-1000 mt-2">CONNEXION</h3>
          </div>
          <form @submit.prevent="loginUser">
            <div class="mb-3 text-start">
              <label class="form-label" for="email">E-mail</label>
              <input class="form-control form-icon-input" v-model="email" id="email" type="email"
                placeholder="Entrez votre e-mail" required />
            </div>
            <div class="mb-3 text-start">
              <label class="form-label" for="password">Mot de passe</label>
              <div class="form-icon-container position-relative">
                <input class="form-control form-icon-input" v-model="password"
                  :type="showPassword ? 'text' : 'password'" placeholder="*********************" id="password"
                  required />
                <button type="button"
                  class="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                  @click="togglePasswordVisibility" style="z-index: 2;">
                  <i :class="showPassword ?  'bi bi-eye' : 'bi bi-eye-slash'"></i>
                </button>
              </div>
            </div>
            <div class="mb-3 text-start">
              <a class="fs--1 fw-semi-bold text-black" href="/resset-password">Mot de passe oublié ?</a>
            </div>
            <button class="btn btn-secondary w-100 mb-3" style="background-color: #ffc107;" type="submit"
              :disabled="isLoading">
              <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span v-else>Se connecter</span>
            </button>
          </form>
          <div class="text-center mt-3">
            <a class="fs--1 fw-semi-bold text-black" href="/signup">S'inscrire</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
  components: { Header },
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      showPassword: false // Ajout de la variable pour gérer la visibilité du mot de passe
    };
  },
  methods: {
    async loginUser() {
      this.isLoading = true;
      try {
        const response = await axios.post('/login', {
          email: this.email,
          password: this.password,
        });

        if (response.data && response.data.token && response.data.user) {
          console.log('Authentification réussie. Token:', response.data.token);
          console.log('Données utilisateur:', response.data.user);
          sessionStorage.setItem('authToken', response.data.token);
          sessionStorage.setItem('userData', JSON.stringify(response.data.user));

          Swal.fire({
            icon: 'success',
            title: 'Connexion réussie',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000
          });

          if (response.data.user.role_id === 2) {
            this.$router.push('/organisateur/apercu');
          } else if (response.data.user.role_id === 1) {
            this.$router.push('/admin/dashboard');
          }
        } else {
          console.error('Échec de l\'authentification. Réponse de l\'API:', response.data);
          Swal.fire({
            icon: 'error',
            title: 'E-mail ou mot de passe incorrect',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
        }
      } catch (error) {
        console.error('Erreur lors de la connexion:', error);
        Swal.fire({
          icon: 'error',
          title: 'Erreur lors de la connexion',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        this.isLoading = false;
      }
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Bascule l'état de visibilité du mot de passe
    }
  }
};
</script>

<style scoped>
.background-image {
  background-image: url('/public/images/background.webp');
  /* Remplacez par le chemin de votre image */
  background-size: cover;
  background-position: center;
}
</style>