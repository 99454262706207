<template>
    <HeaderDash />

    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-7 col-12">
                        <div class="custom-block bg-white">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile-tab-pane" type="button" role="tab"
                                            aria-controls="profile-tab-pane" aria-selected="true">
                                        Profil
                                    </button>
                                </li>

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="password-tab" data-bs-toggle="tab"
                                            data-bs-target="#password-tab-pane" type="button" role="tab"
                                            aria-controls="password-tab-pane" aria-selected="false">
                                        Mot de passe
                                    </button>
                                </li>
                            </ul>

                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="profile-tab-pane" role="tabpanel"
                                     aria-labelledby="profile-tab" tabindex="0">
                                    <form class="custom-form profile-form" role="form">
                                        <input v-model="first_name" class="form-control" type="text"
                                               placeholder="Prénom" />
                                        <input v-model="last_name" class="form-control" type="text"
                                               placeholder="Nom" />
                                        <input v-model="phone" class="form-control" type="tel"
                                               placeholder="Téléphone" />
                                        <input type="file" @change="handleLogoChange" class="form-control"
                                               accept="image/*" />
                                        <div class="d-flex">
                                            <button type="submit" class="form-control ms-2"
                                                    @click.prevent="updateEventOwner">Mettre à jour
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane fade" id="password-tab-pane" role="tabpanel"
                                     aria-labelledby="password-tab" tabindex="0">
                                    <form class="custom-form profile-form" @submit.prevent="updatePassword"
                                          role="form">
                                        <input v-model="oldPassword" class="form-control" type="password"
                                               placeholder="Ancien mot de passe" />
                                        <input v-model="newPassword" class="form-control" type="password"
                                               placeholder="Nouveau mot de passe" />

                                        <button type="submit" class="form-control ms-2">Mettre à jour</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="custom-block custom-block-contact">
                            <h6 class="mb-4 text-center">
                                Vous ne trouvez toujours pas ce que vous cherchez ?
                            </h6>
                            <div class="d-flex justify-content-center align-items-center mt-3">
                                <a href="https://wa.me/41438809?text=Bonjour%20*MOUGNI*"
                                   class="btn custom-btn custom-btn-bg-white">
                                    <i class="fab fa-whatsapp me-2"></i> Discutez avec nous
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</template>

<script>
import HeaderDash from "@/components/HeaderDash.vue";
import SideBar from "@/components/SideBar.vue";
import axios from "@/axios/axios.js";
import Swal from 'sweetalert2';

export default {
    components: { HeaderDash, SideBar },
    data() {
        return {
            first_name: '',
            last_name: '',
            phone: '',
            photoFile: null,
            oldPassword: '',
            newPassword: ''
        };
    },
    mounted() {
        this.initializeFormData();
    },
    methods: {
        handleLogoChange(event) {
            this.photoFile = event.target.files[0];
        },

        updateEventOwner() {
            const formData = new FormData();

            formData.append("first_name", this.first_name);
            formData.append("last_name", this.last_name);
            formData.append("phone", this.phone);
            formData.append("photo", this.photoFile);
            formData.append('_method', 'put');

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const eventOwnerId = userData.eventowner.id;

            axios.post(`/eventowner/${eventOwnerId}/update`, formData)
                .then(response => {
                    console.log(response.data);

                    // Réinitialisation des champs après la mise à jour réussie
                    this.first_name = '';
                    this.last_name = '';
                    this.phone = '';
                    this.photoFile = null;

                    Swal.fire({
                        icon: 'success',
                        title: 'Mise à jour réussie!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .catch(error => {
                    console.error(error);
                });
        },

        updatePassword() {
            const payload = {
                old_password: this.oldPassword,
                new_password: this.newPassword
            };

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const UserId = userData.id;
            axios.put(`/users/${UserId}/update-password`, payload)
                .then(response => {
                    console.log(response.data);

                    Swal.fire({
                        icon: 'success',
                        title: 'Mot de passe mis à jour avec succès !',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    this.oldPassword = '';
                    this.newPassword = '';
                })
                .catch(error => {
                    console.error(error);
                });
        },

        initializeFormData() {
            // Récupérer les données de l'utilisateur depuis sessionStorage
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (userData && userData.eventowner) {
                this.first_name = userData.eventowner.first_name || '';
                this.last_name = userData.eventowner.last_name || '';
                this.phone = userData.eventowner.phone || '';
            }
        }
    }
}
</script>

<style></style>
