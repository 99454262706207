<template>
    <Header /><br />
    <section class="hero-section pt-3 pb-5 pb-lg-5 pt-lg-5 text-white" style="background-color: #EE5007;">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-8">
                    <div class="hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0"
                        data-aos="fade-up">
                        <h1 class="fw-normal text-capitalize text-center display-5 mb-1">Évènements disponibles</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="d-none d-lg-block">
        <div wire:id="MfkgSk5rd9iRT46hlqE3"
            wire:initial-data="{&quot;fingerprint&quot;:{&quot;id&quot;:&quot;MfkgSk5rd9iRT46hlqE3&quot;,&quot;name&quot;:&quot;events&quot;,&quot;locale&quot;:&quot;fr_FR&quot;,&quot;path&quot;:&quot;events&quot;,&quot;method&quot;:&quot;GET&quot;,&quot;v&quot;:&quot;acj&quot;},&quot;effects&quot;:{&quot;listeners&quot;:[],&quot;path&quot;:&quot;https:\/\/paxframe.com\/events&quot;},&quot;serverMemo&quot;:{&quot;children&quot;:[],&quot;errors&quot;:[],&quot;htmlHash&quot;:&quot;95c2ab17&quot;,&quot;data&quot;:{&quot;search&quot;:&quot;&quot;,&quot;category&quot;:0,&quot;perPage&quot;:9,&quot;page&quot;:1,&quot;paginators&quot;:{&quot;page&quot;:1}},&quot;dataMeta&quot;:[],&quot;checksum&quot;:&quot;b09078ee5dc9cda5dd9045919880f253ebedd0119e77f24fed340ffdf30a598a&quot;}}">
            <div class="container">
                <div class="row mt-4 justify-content-start">
                    <div class="col-lg-3 col-md-6">
                        <label class="filter-label fw-normal font-12 mx-1">Filtrer par catégorie:</label>
                        <select wire:change="resetTerm" wire:model="category" class="form-control form-select"
                            data-msg="Sélectionner une catégorie">
                            <option value="0">Toutes les catégories</option>
                            <option value="1">Apparition ou Dédicace</option>
                            <option value="2">Attractions</option>
                            <option value="3">Classe, Formation ou Atelier</option>
                            <option value="4">Colonie de vacances, Voyage ou Retraite</option>
                            <option value="5">Concert ou performance</option>
                            <option value="6">Conférence</option>
                            <option value="7">Convention</option>
                            <option value="8">Dîner ou Gala</option>
                            <option value="9">Festival ou Salon</option>
                            <option value="10">Fête ou Rassemblement social</option>
                            <option value="11">Matchs, jeux ou compétitions</option>
                            <option value="12">Rencontres ou Évènement de Réseautage</option>
                            <option value="13">Salon professionel</option>
                            <option value="14">Séminaire</option>
                            <option value="15">Visite guidée</option>
                            <option value="16">Autres</option>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label class="filter-label fw-normal font-12 mx-1">Rechercher par mot clé:</label>
                        <input class="form-control" wire:model="search">
                    </div>
                </div>
            </div>
            <section class="masonary-blog-section ptb-60">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/vdd-masterclass" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/FJ2awfIFRoXENcTTjBi73zCIjkOT27zEFgn0FXcY.jpg"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Classe, Formation ou Atelier
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            27 avril 2024 à 09:00
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/vdd-masterclass">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            VDD MASTERCLASS
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/pepino-georgah-1">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">Pepino GEORGAH</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 2 avril 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/english-club" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/clibFqe2NMzJdyTSRMU0sJtLoP3Pxa8w8dszY6x4.png"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Conférence
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            27 mars 2024 à 12:41
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/english-club">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            English Club
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/english-club">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">English Club</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 20 mars 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/shoot-and-chill" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/F8eVZ2nLGEjxsuSPp0xowrACFxg6IMvlDsp8NfFq.jpg"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Rencontres ou Évènement de Réseautage
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            27 avril 2024 à 08:00
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/shoot-and-chill">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            SHOOT AND CHILL
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/amg">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">AMG</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 15 mars 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mx-2 mt-4">
                    </div>

                </div>
            </section>
        </div>
    </div>
    <div class="d-lg-none">
        <div wire:id="scMIsLt6Ng6Y4pykqZlB"
            wire:initial-data="{&quot;fingerprint&quot;:{&quot;id&quot;:&quot;scMIsLt6Ng6Y4pykqZlB&quot;,&quot;name&quot;:&quot;events&quot;,&quot;locale&quot;:&quot;fr_FR&quot;,&quot;path&quot;:&quot;events&quot;,&quot;method&quot;:&quot;GET&quot;,&quot;v&quot;:&quot;acj&quot;},&quot;effects&quot;:{&quot;listeners&quot;:[],&quot;path&quot;:&quot;https:\/\/paxframe.com\/events&quot;},&quot;serverMemo&quot;:{&quot;children&quot;:[],&quot;errors&quot;:[],&quot;htmlHash&quot;:&quot;28a92630&quot;,&quot;data&quot;:{&quot;search&quot;:&quot;&quot;,&quot;category&quot;:0,&quot;perPage&quot;:6,&quot;page&quot;:1,&quot;paginators&quot;:{&quot;page&quot;:1}},&quot;dataMeta&quot;:[],&quot;checksum&quot;:&quot;def67abcb253c145d02edef7eab5f72840b8222b0dd2eb93ab26b03012736353&quot;}}">
            <div class="container">
                <div class="row mt-4 justify-content-start">
                    <div class="col-lg-3 col-md-6">
                        <label class="filter-label fw-normal font-12 mx-1">Filtrer par catégorie:</label>
                        <select wire:change="resetTerm" wire:model="category" class="form-control form-select"
                            data-msg="Sélectionner une catégorie">
                            <option value="0">Toutes les catégories</option>
                            <option value="1">Apparition ou Dédicace</option>
                            <option value="2">Attractions</option>
                            <option value="3">Classe, Formation ou Atelier</option>
                            <option value="4">Colonie de vacances, Voyage ou Retraite</option>
                            <option value="5">Concert ou performance</option>
                            <option value="6">Conférence</option>
                            <option value="7">Convention</option>
                            <option value="8">Dîner ou Gala</option>
                            <option value="9">Festival ou Salon</option>
                            <option value="10">Fête ou Rassemblement social</option>
                            <option value="11">Matchs, jeux ou compétitions</option>
                            <option value="12">Rencontres ou Évènement de Réseautage</option>
                            <option value="13">Salon professionel</option>
                            <option value="14">Séminaire</option>
                            <option value="15">Visite guidée</option>
                            <option value="16">Autres</option>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <label class="filter-label fw-normal font-12 mx-1">Rechercher par mot clé:</label>
                        <input class="form-control" wire:model="search">
                    </div>
                </div>
            </div>
            <section class="masonary-blog-section ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/vdd-masterclass" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/FJ2awfIFRoXENcTTjBi73zCIjkOT27zEFgn0FXcY.jpg"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Classe, Formation ou Atelier
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            27 avril 2024 à 09:00
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/vdd-masterclass">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            VDD MASTERCLASS
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/pepino-georgah-1">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">Pepino GEORGAH</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 2 avril 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/english-club" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/clibFqe2NMzJdyTSRMU0sJtLoP3Pxa8w8dszY6x4.png"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Conférence
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            27 mars 2024 à 12:41
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/english-club">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            English Club
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/english-club">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">English Club</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 20 mars 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/shoot-and-chill" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/F8eVZ2nLGEjxsuSPp0xowrACFxg6IMvlDsp8NfFq.jpg"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Rencontres ou Évènement de Réseautage
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            27 avril 2024 à 08:00
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/shoot-and-chill">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            SHOOT AND CHILL
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/amg">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">AMG</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 15 mars 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/godig-service" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/0C1HcBkKTdpxDS3j4mapzTu63fthebX7X5L6WeQX.png"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Conférence
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            25 janvier 2024 à 13:20
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/godig-service">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            GODIG service
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/deb-dem">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">DEB-DEM</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 24 janvier 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/annee-2024" class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/nzLo2jEdtafGRM87v4K5HEY7IMVSqFMrT37MBWGJ.png"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Autres
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            3 janvier 2024 à 12:02
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/annee-2024">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            Année 2024
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/icc-casablanca">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/assets/dashboard/images/logo-sm.png"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">Icc Casablanca</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 3 janvier 2024
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-article rounded-custom my-3">
                                <a href="https://paxframe.com/events/payez-votre-droit-dadhesion-a-djigbezan-local"
                                    class="article-img">
                                    <img src="https://paxframe.com/storage/images/events/2AwJ0fCVUCfh0vRVp7aTp1399IfM37m2NSuJU2yA.jpg"
                                        alt="Event" class="img-fluid">
                                </a>
                                <div class="article-content p-4">
                                    <div class="article-category mb-2">
                                        <a href="javascript:void(0)"
                                            class="d-inline-block text-dark badge bg-warning-soft">
                                            Fête ou Rassemblement social
                                        </a>
                                        <span class="d-inline-block text-white badge bg-primary">
                                            1 janvier 2024 à 00:00
                                        </span>
                                    </div>
                                    <a href="https://paxframe.com/events/payez-votre-droit-dadhesion-a-djigbezan-local">
                                        <h2 class="h6 article-title text-primary text-truncate">
                                            PAYEZ VOTRE DROIT D&#039;ADHÉSION À DJIGBÉZAN LOCAL
                                        </h2>
                                    </a>
                                    <a href="https://paxframe.com/organisateur/azowato-1">
                                        <div class="d-flex align-items-center pt-2">
                                            <div class="avatar">
                                                <img src="https://paxframe.com/storage/images/avatars/ogi7LFiy0ubdiiuvV2qNLTYblpx47wvbcm7fnv4r.jpg"
                                                    alt="avatar" width="40" class="img-fluid rounded-circle me-3">
                                            </div>
                                            <div class="avatar-info">
                                                <h6 class="mb-0 avatar-name limit text-dark">AZOWATO</h6>
                                                <span class="small fw-normal text-muted">
                                                    Publié le 4 décembre 2023
                                                </span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mx-2 mt-4">
                        <nav>
                            <ul class="pagination">
                                <li class="page-item disabled" aria-disabled="true" aria-label="&laquo; Previous">
                                    <span class="page-link" aria-hidden="true">
                                        Précédent
                                    </span>
                                </li>

                                <div class="d-none d-lg-flex">

                                    <li class="page-item active" wire:key="paginator-page-1-page-1" aria-current="page">
                                        <span class="page-link">1</span>
                                    </li>
                                    <li class="page-item" wire:key="paginator-page-1-page-2">
                                        <button type="button" class="page-link"
                                            wire:click="gotoPage(2, 'page')">2</button>
                                    </li>
                                </div>


                                <li class="page-item">
                                    <button type="button" dusk="nextPage" class="page-link"
                                        wire:click="nextPage('page')" wire:loading.attr="disabled" rel="next"
                                        aria-label="Next &raquo;">
                                        Suivant
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </section>
        </div>
    </div>

</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";

export default {
    components: { Header },


}
</script>


<style></style>