<template>
    <div>
        <HeaderDash />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                    <!-- Formulaire pour ajouter un article de blog -->
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Ajouter un Article de Blog</h5>
                                <form @submit.prevent="submitArticle">
                                    <div class="mb-3">
                                        <label for="title" class="form-label">Titre de l'article</label>
                                        <input type="text" class="form-control" id="title" v-model="article.title" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="coverImage" class="form-label">Image de couverture</label>
                                        <input type="file" class="form-control" id="coverImage" @change="handleFileUpload" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="content" class="form-label">Contenu</label>
                                        <textarea id="editor" v-model="article.content"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="author" class="form-label">Auteur</label>
                                        <input type="text" class="form-control" id="author" v-model="article.author" required>
                                    </div>
                                    <button type="submit" class="btn btn-primary">
                                        <span v-if="isSubmitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span v-if="!isSubmitting">Soumettre</span>
                                        <span v-if="isSubmitting"> En cours...</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- Affichage des articles de blog -->
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Articles de Blog</h5>
                                <div v-if="articles.length === 0">Aucun article trouvé.</div>
                                <div v-else>
                                    <div class="row">
                                        <div v-for="article in articles" :key="article.id" class="col-md-4 mb-4">
                                            <div class="card">
                                                <img v-if="article.cover_image" :src="BACK_URL + article.cover_image" class="card-img-top" alt="Image de couverture">
                                                <div class="card-body">
                                                    <h5 class="card-title">{{ article.title }}</h5>
                                                </div>
                                                <div class="card-footer text-muted">
                                                    Auteur : {{ article.author }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Pagination -->
                                <nav v-if="pagination.totalPages > 1" aria-label="Page navigation">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item" :class="{ disabled: pagination.currentPage === 1 }">
                                            <a class="page-link" href="#" @click.prevent="fetchArticles(pagination.currentPage - 1)">Précédent</a>
                                        </li>
                                        <li class="page-item" v-for="page in pagination.totalPages" :key="page" :class="{ active: page === pagination.currentPage }">
                                            <a class="page-link" href="#" @click.prevent="fetchArticles(page)">{{ page }}</a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: pagination.currentPage === pagination.totalPages }">
                                            <a class="page-link" href="#" @click.prevent="fetchArticles(pagination.currentPage + 1)">Suivant</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderDash from "@/components/HeaderDash.vue";
import SideBar from "@/components/SideBar.vue";
import axios from "@/axios/axios.js";
import { BACK_URL } from '@/article_image.js';
import Swal from 'sweetalert2';

export default {
    components: { HeaderDash, SideBar },
    data() {
        return {
            BACK_URL: BACK_URL,
            article: {
                title: '',
                content: '',
                author: ''
            },
            coverImage: null,
            articles: [],
            isSubmitting: false,
            pagination: {
                currentPage: 1,
                totalPages: 0
            }
        };
    },
    mounted() {
        this.fetchArticles();
        // Initialisation de CKEditor
        CKEDITOR.replace('editor');
    },
    methods: {
        handleFileUpload(event) {
            this.coverImage = event.target.files[0];
        },
        async fetchArticles(page = 1) {
            try {
                const response = await axios.get(`/articles?page=${page}`);
                this.articles = response.data.data;
                this.pagination.currentPage = response.data.current_page;
                this.pagination.totalPages = response.data.last_page;
            } catch (error) {
                Swal.fire('Erreur', 'Impossible de récupérer les articles.', 'error');
            }
        },
        async submitArticle() {
            this.isSubmitting = true;
            try {
                // Créer un FormData pour inclure le fichier et les autres données
                const formData = new FormData();
                formData.append('title', this.article.title);
                formData.append('content', CKEDITOR.instances.editor.getData());
                formData.append('author', this.article.author);
                if (this.coverImage) {
                    formData.append('coverImage', this.coverImage);
                }

                const response = await axios.post('/articles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                this.articles.push(response.data);
                Swal.fire('Succès', 'Article ajouté avec succès.', 'success');
                this.article.title = '';
                this.article.content = '';
                this.article.author = '';
                CKEDITOR.instances.editor.setData('');
                this.coverImage = null;
                document.getElementById('coverImage').value = '';
            } catch (error) {
                Swal.fire('Erreur', 'Impossible d\'ajouter l\'article.', 'error');
            } finally {
                this.isSubmitting = false;
            }
        },
    },
};
</script>

<style scoped>
/* Styles personnalisés */
</style>
