<template>
    <div>
        <HeaderDash />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">

                    <!-- Nouvelle section pour importer les numéros de téléphone -->
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <div class="row mb-4">
                                    <div class="col-md-12">
                                        <h4>Importer des numéros de téléphone</h4>
                                        <form @submit.prevent="submitPhoneNumberImport">
                                            <div class="mb-3">
                                                <label for="phoneNumbersFile" class="form-label">Fichier Excel ou
                                                    CSV</label>
                                                <input type="file" class="form-control" id="phoneNumbersFile"
                                                    accept=".xlsx,.csv" @change="handleFileChange">
                                            </div>
                                            <button type="submit" class="btn btn-primary">
                                                <span v-if="isImporting" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                                <span v-else>Importer</span>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Mes numéros</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #000;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">TELEPHONE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(phoneNumber, index) in phoneNumbers.data" :key="phoneNumber.id">
                                                <td>{{ index + 1 + (phoneNumbers.current_page - 1) *
                                                    phoneNumbers.per_page }}</td>
                                                <td>{{ phoneNumber.phone_number }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <nav>
                                    <ul class="pagination">
                                        <li class="page-item" :class="{ disabled: !phoneNumbers.prev_page_url }">
                                            <a class="page-link" href="#"
                                                @click.prevent="fetchPhoneNumbers(phoneNumbers.prev_page_url)">&laquo;
                                                Précédent</a>
                                        </li>
                                        <li class="page-item" v-for="link in phoneNumbers.links" :key="link.label"
                                            :class="{ active: link.active, disabled: !link.url }">
                                            <a class="page-link" href="#" @click.prevent="fetchPhoneNumbers(link.url)"
                                                v-html="link.label"></a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: !phoneNumbers.next_page_url }">
                                            <a class="page-link" href="#"
                                                @click.prevent="fetchPhoneNumbers(phoneNumbers.next_page_url)">Suivant
                                                &raquo;</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderDash from "@/components/HeaderDash.vue";
import SideBar from "@/components/SideBar.vue";
import axios from "@/axios/axios.js";
import Swal from 'sweetalert2';
import { FedaPayPublicKey } from '@/fedapay';

export default {
    components: { HeaderDash, SideBar },
    data() {
        return {
            phoneNumbers: {
                data: [],
                links: [],
                prev_page_url: null,
                next_page_url: null
            },
            isImporting: false,
            phoneNumbersFile: null
        };
    },

    methods: {

        handleFileChange(event) {
            this.phoneNumbersFile = event.target.files[0];
        },

        submitPhoneNumberImport() {
            this.isImporting = true;
            const formData = new FormData();
            formData.append('file', this.phoneNumbersFile);

            axios.post('/import-phone-numbers', formData)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Importation réussie',
                        text: 'Les numéros de téléphone ont été importés avec succès!',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    // Vous pouvez ajouter d'autres actions après l'importation réussie
                })
                .catch(error => {
                    console.error(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur lors de l\'importation',
                        text: 'Une erreur est survenue lors de l\'importation des numéros de téléphone.',
                        showConfirmButton: false,
                        timer: 3000
                    });
                })
                .finally(() => {
                    this.isImporting = false;
                    // Réinitialisation éventuelle du champ de fichier
                    this.phoneNumbersFile = null;
                });
        },

        fetchPhoneNumbers(url = '/get-phone-numbers') {
            axios.get(url)
                .then(response => {
                    this.phoneNumbers = response.data;
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des numéros de téléphone:', error);
                });
        }
    },
    mounted() {
        this.fetchPhoneNumbers();
    }
}
</script>


<style>
/* Vous pouvez ajouter des styles personnalisés pour le spinner ici */
</style>
