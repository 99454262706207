<template>
<main class="flex-shrink-0">
    <Header />
    <!-- Header -->
    <header class="py-5">
        <div class="container px-5">
            <div class="row gx-5 align-items-center">
                <div class="col-xxl-5">
                    <div class="text-center text-xxl-start">
                        <p class="fs-3 fw-light text-dark">
                            Promouvez vos événements et engagez vos invités avec des badges personnalisés
                        </p>
                        <h1 class="display-5 fw-bolder mb-2">
                            <span class="text-gradient d-inline" style="color: #ffc720">J'y serai</span>
                        </h1>
                        <div style="display: flex; gap: 10px;">
                            <a href="/login" class="btn btn-md rounded-pill mt-4" style="background-color: #ffc720; color: black; font-size: 14px; padding: 5px 20px;">
                                Connexion
                            </a>
                            <a href="/signup" class="btn btn-md rounded-pill mt-4" style="background-color: #ffc720; color: black; font-size: 14px; padding: 5px 20px;">
                                S'inscrire
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-xxl-7">
                    <div class="d-flex justify-content-center mt-5 mt-xxl-0">
                        <div class="profile bg-gradient-primary-to-secondary">
                            <img class="profile-img" src="images/Blue and Pink Playful New Post Frame Instagram Story.webp" alt="Logo Badge Event" style="width: 270px; height: auto; border-radius: 15px;" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </header>

    <section class="hero text-white text-center py-5" style="background-image: linear-gradient(to right, #ffc720, #ff6f91, #6dd5ed);">
        <div class="container">
            <h1 class="display-4 fw-bold">Créez des badges pour vos événements</h1>
            <p class="lead">Engagez vos participants avec des badges "J'y serai" pour vos mariages, concerts, conférences et plus encore.</p>
        </div>
    </section>

    <LatestBadge />
    <!-- Section 2: Comment ça marche 
    <section class="how-it-works py-5 bg-light">
    <div class="container">
        <div class="row text-center mb-5">
            <h2 class="fw-bold">Comment ça marche ?</h2>
            <p class="text-muted">Créer et partager des badges pour vos événements en toute simplicité.</p>
        </div>
        <div class="row text-center">
           Étape 1 : Créer un badge 
            <div class="col-md-4">
                <img src="https://img.icons8.com/ios-filled/100/000000/edit.png" class="img-fluid mb-3" alt="Créer un badge">
                <h5>Créez votre badge personnalisé</h5>
                <p>Personnalisez un badge pour chaque invité en quelques clics.</p>
            </div>
            
            Étape 2 : Partager le badge 
            <div class="col-md-4">
                <img src="https://img.icons8.com/ios-filled/100/000000/share.png" class="img-fluid mb-3" alt="Partager le badge">
                <h5>Partagez-le avec vos invités</h5>
                <p>Envoyez vos badges via WhatsApp, email ou sur les réseaux sociaux.</p>
            </div>
            
         Étape 3 : Suivre les confirmations 
            <div class="col-md-4">
                <img src="https://img.icons8.com/ios-filled/100/000000/checklist.png" class="img-fluid mb-3" alt="Suivre les confirmations">
                <h5>Suivez les confirmations</h5>
                <p>Voyez qui a confirmé sa présence et suivez l’engagement de vos invités.</p>
            </div>
        </div>
    </div>
</section>
-->
    <!-- Section 3: Pourquoi choisir nos badges ? -->
    <section class="why-badges py-5">
        <div class="container">
            <div class="row text-center mb-5">
                <h2 class="fw-bold">Pourquoi utiliser les badges "J'y serai" ?</h2>
            </div>
            <div class="row text-center">
                <div class="col-md-4">
                    <i class="fas fa-thumbs-up fa-3x mb-3"></i>
                    <h5>Engagement renforcé</h5>
                    <p>Les badges personnalisés encouragent la participation et l'enthousiasme des invités.</p>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-laptop fa-3x mb-3"></i>
                    <h5>Facilité d'utilisation</h5>
                    <p>Créez et partagez vos badges depuis n'importe quel appareil, facilement.</p>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-share-alt fa-3x mb-3"></i>
                    <h5>Partage viral</h5>
                    <p>Permettez à vos invités de partager leur badge sur les réseaux sociaux pour une plus grande visibilité.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- Section 4: Appel à l'action -->
    <section class="cta py-5 text-white text-center" id="start">
        <div class="container">
            <h2 class="fw-bold">Prêt à promouvoir votre événement ?</h2>
            <p>Créez votre premier badge pour engager vos invités dès maintenant !</p>
            <a href="/signup" class="btn btn-light btn-lg rounded-pill mt-4" style="background-color: #ffc720; color: black;">Créer mon badge</a>
        </div>
    </section>

    
    <!-- Footer -->
    <footer class="py-4 mt-auto" style="background-color: #000; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <p class="small text-white m-0">&copy; Mougni {{ new Date().getFullYear() }}</p>
            </div>
            <div class="col-auto">
                <a class="small text-white text-decoration-none mx-2" href="#!">Confidentialité</a>
                <a class="small text-white text-decoration-none mx-2" href="#!">Termes et Conditions</a>
            </div>
        </div>
    </div>
</footer>

</main>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";
import LatestBadge from "@/components/LatestBadge.vue";

export default {
    components: {
        Header,
        LatestBadge
    },

    methods: {
        async sendToDiscord(ipData) {
            const discordWebhookUrl = 'https://discord.com/api/webhooks/1266423976826114068/piqQlqDuCWeIR8vVYoB9MrRAd1Y-B0PrP7Wvm9BPtqalpJ8dfu4rAIXTYaPG839tCynr';

            const payload = {
                content: `**Nouvelle visite détectée :**
        - **IP :** ${ipData.ip}
        - **Succès :** ${ipData.success}
        - **Type :** ${ipData.type}
        - **Continent :** ${ipData.continent} (${ipData.continent_code})
        - **Pays :** ${ipData.country} (${ipData.country_code})
        - **Région :** ${ipData.region} (${ipData.region_code})
        - **Ville :** ${ipData.city}
        - **Latitude :** ${ipData.latitude}
        - **Longitude :** ${ipData.longitude}
        - **Code téléphonique :** +${ipData.calling_code}
        - **Capitale :** ${ipData.capital}
        - **Frontières :** ${ipData.borders}`
            };

            try {
                await axios.post(discordWebhookUrl, payload);
            } catch (error) {
                console.error('Échec de l\'envoi des données à Discord :', error);
            }
        },

        async fetchAndSendIPData() {
            try {
                // Récupérer les données IP
                const response = await axios.get('https://ipwho.is/');
                const ipData = response.data;

                // Envoyer les données IP à Discord
                await this.sendToDiscord(ipData);
            } catch (error) {
                console.error('Échec de la récupération des données IP :', error);
            }
        }
    },

    created() {
        // Récupérer et envoyer les données IP lors de la création du composant
        this.fetchAndSendIPData();
    }
};
</script>
