<template>
    <section class="hero-section py-5 position-relative overflow-hidden" style="background-color: #f8f7f2;">
        <div class="container">
            <div class="hero-content-wrap">
                <h3 class="fw-bold display-5 text-center">J'y serai et toi ?</h3>
                <br />
            </div>
            <div class="row">
                <div v-for="badge in badges" :key="badge.badge.id" class="col-md-4 mb-4">
                    <div class="card shadow-sm d-flex flex-row align-items-center p-3" style="border-radius: 15px;"> 
                        <!-- Image du badge à gauche -->
                        <div class="flex-shrink-0">
                            <img :src="BACK_URL + badge.badge.badge" class="img-fluid rounded-start" alt="J'y serai" style="width: 100px; height: 100px;">
                        </div>
                        <!-- Informations du badge à droite -->
                        <div class="card-body ms-3">
                            <h5 class="card-title">{{ badge.badge.name }}</h5>
                            <a :href="'/badges/generate-badges/' + badge.badge.id" class="btn btn-warning mt-2 rounded-pill">voir le badge</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from "@/axios/axios.js";
import { BACK_URL } from '@/event_badge.js';

export default {
    data() {
        return {
            badges: [],
            BACK_URL: BACK_URL,
        };
    },
    mounted() {
        this.fetchLatestBadges();
    },
    methods: {
        fetchLatestBadges() {
            axios.get(`/latest-badges`)
                .then(response => {
                    this.badges = response.data.badges;
                })
                .catch(error => {
                    console.error('Error fetching the latest badges:', error);
                });
        }
    }
}
</script>


<style></style>