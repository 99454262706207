<template>
    <HeaderDash />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="title-group mb-3">
                    <h5 class="h5 mb-0 text-center">{{ greeting }} {{ userData && userData.first_name }}, bienvenue sur
                        Mougni !
                    </h5>
                </div>

                <div class="row my-4">
                    <div class="col-lg-7 col-12">
                        <div class="custom-block custom-block-balance">
                            <small>SOLDE</small>
                            <h2 class="mt-2 mb-3">0 FCFA</h2>
                            <div class="d-flex">
                                <div>
                                    <small>Date d'inscription</small>
                                    <p>{{ formattedCreatedAt }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Statistiques (Badge)</h5>
                            <canvas id="doughnutChart" height="350"></canvas>
                        </div>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white">
                            <div class="custom-block-profile-image-wrap mb-4">
                                <img v-if="userData && userData.photo" :src="BACK_URL + userData.photo"
                                    class="custom-block-profile-image img-fluid" alt="">
                                <img v-else src="/../images/mougni_default_profil.jpeg"
                                    class="custom-block-profile-image img-fluid" alt="">
                                <a href="/organisateur/parametre" class="bi-pencil-square custom-block-edit-icon"></a>
                            </div>

                            <p class="d-flex flex-wrap mb-2">
                                <strong>Nom:</strong>
                                <span>{{ userData && userData.first_name }}</span>
                            </p>

                            <p class="d-flex flex-wrap mb-2">
                                <strong>Prénom:</strong>
                                <span>{{ userData && userData.last_name }}</span>
                            </p>

                            <p class="d-flex flex-wrap mb-2">
                                <strong>Télephone:</strong>
                                <span>{{ userData && userData.phone }}</span>
                            </p>
                        </div>

                        <div class="custom-block custom-block-bottom d-flex flex-wrap">
                            <div class="custom-block-bottom-item">
                                <a href="/organisateur/mes-badges" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-upload"></i>
                                    <small>Soumettre Badge</small>
                                </a>
                            </div>

                            <div class="custom-block-bottom-item">
                                <a href="/badges/all-badges" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-eye"></i>
                                    <small>Visualiser</small>
                                </a>
                            </div>

                            <!--      <div class="custom-block-bottom-item">
                                <a href="/organisateur/whatsappsms" class="d-flex flex-column">
                                    <i class="custom-block-icon bi-whatsapp"></i>
                                    <small>WhatsApp</small>
                                </a>
                            </div>-->
                        </div> 
                            <div class="custom-block custom-block-bottom d-flex flex-wrap">
                                <div class="custom-block-bottom-item">
                                    <a href="#" class="d-flex flex-column" @click="generatePDF">
                                        <i class="custom-block-icon bi-book"></i>
                                        <small>Guide d'utulisation</small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
            </main>
        </div>
    </div>
</template>


<script>
import Swal from 'sweetalert2';
import HeaderDash from "@/components/HeaderDash.vue";
import SideBar from "@/components/SideBar.vue";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from "@/axios/axios.js";
import Chart from "chart.js/auto";
import { BACK_URL } from "@/organisateur_image.js";

export default {
    components: {
        HeaderDash,
        SideBar
    },
    data() {
        return {
            userData: null,
            BACK_URL,
            createdAt: '',
            greeting: ''
        };
    },

    computed: {
        formattedCreatedAt() {
            if (!this.createdAt) return '';
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            return new Date(this.createdAt).toLocaleDateString('fr-FR', options);
        }
    },

    mounted() {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (userData && userData.id) {
            const userId = userData.id;
            this.createdAt = userData.created_at;

            axios.get(`/event_badges/stats/${userId}`)
                .then(response => {
                    const ctx = document.getElementById('doughnutChart').getContext('2d');
                    new Chart(ctx, {
                        type: 'doughnut',
                        data: {
                            labels: ['Vues', 'Téléchargements'],
                            datasets: [{
                                label: 'Statistiques',
                                data: [response.data.total_views, response.data.total_downloads],
                                backgroundColor: ['#0d6efd', '#dc3545'],
                                hoverOffset: 4
                            }]
                        },
                        options: {
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top'
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            let label = context.label || '';
                                            if (label) {
                                                label += ': ';
                                            }
                                            if (context.raw !== null) {
                                                label += context.raw;
                                            }
                                            return label;
                                        }
                                    }
                                }
                            }
                        }
                    });
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des statistiques:', error);
                });
        } else {
            console.error('ID utilisateur non trouvé dans le sessionStorage');
        }

        axios.get('/profile')
            .then(response => {
                this.userData = response.data.data;
                this.greeting = this.getGreeting();
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des informations du profil:', error);
            });
    },
    methods: {
        getGreeting() {
            const hour = new Date().getHours();
            if (hour >= 5 && hour < 12) {
                return "Bonjour";
            } else if (hour >= 12 && hour < 18) {
                return "Bonjour";
            } else {
                return "Bonsoir";
            }
        },

        generatePDF() {
            // Affichez l'alerte SweetAlert
            Swal.fire({
                title: 'Exportation',
                text: 'Êtes-vous sûr de vouloir télecharger le guide ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
            }).then((result) => {
                if (result.isConfirmed) {

                    const doc = new jsPDF();
                    const totalPagesExp = "{total_pages_count_string}";
                    let totalPages = 1;

                    doc.setFillColor(0, 0, 0);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
                    const imgDataLeft = '../images/Mougni.png';
                    doc.addImage(imgDataLeft, 'JPEG', 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 23;
                    const text = 'GUIDE D\'UTULISATON DE LA PLATEFORME MOUGNI';
                    doc.setTextColor('#ffffff');
                    doc.text(text, middleX, middleY, { align: 'center' });
                    doc.setTextColor(0, 0, 0);

                    doc.autoTable({
                        startY: 45,
                        headStyles: { fillColor: [0, 102, 102], textColor: '#ffffff' },
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text("Page " + data.pageNumber + " sur " + totalPages, 14, doc.internal.pageSize.height - bottomMargin);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text("imprimé le: " + dateString + " à " + timeString, 14, doc.internal.pageSize.height - bottomMargin + 5); // Décalage vertical pour la deuxième ligne
                        },
                    });

                    doc.save('guide_dutulisation.pdf');
                    Swal.fire({
                        title: 'Succès !',
                        text: 'L\'exportation en PDF a réussi.',
                        icon: 'success',
                    });
                }
            });
        },
    }
};
</script>


<style></style>