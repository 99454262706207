<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-#EE5007 py-3 fixed-top">
    <div class="container px-5">
      <a class="navbar-brand" href="/">
        <h3 class="text-white">Mougni</h3>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end offcanvas-half" tabindex="-1" id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title text-white" id="offcanvasNavbarLabel">Menu</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
            <li v-if="!hasAuthToken" class="nav-item">
              <a class="nav-link text-white fw-bold" href="/">Accueil</a>
            </li>
            <li v-if="!hasAuthToken" class="nav-item">
              <a class="nav-link text-white fw-bold" href="/badges/all-badges">Badge</a>
            </li>
         <!--    <li v-if="!hasAuthToken" class="nav-item">
              <a class="nav-link text-white fw-bold" href="/blogs">Blog</a>
            </li> -->
            <li v-if="!hasAuthToken" class="nav-item">
              <a class="nav-link text-white fw-bold" href="/pricing">Pricing</a>
            </li>
            <!--  <li v-if="!hasAuthToken" class="nav-item"><a class="nav-link text-white fw-bold" href="/ticket/all-tickets">E-Ticket</a></li> -->
            <li v-if="!hasAuthToken" class="nav-item">
              <a class="nav-link text-white fw-bold" href="https://wa.me/41438809?text=Bonjour%20*MOUGNI*">
                Contact
              </a>
            </li>
            <li v-if="hasAuthToken" class="nav-item">
              <button @click="logoutUser" class="btn btn-danger text-white">
                Déconnexion
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <br /><br />
</template>

<script>
import axios from "@/axios/axios.js";

export default {
  methods: {
    async logoutUser() {
      try {
        const response = await axios.post('/logout', null, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
          },
        });

        if (response.status === 200) {
          console.log('Déconnexion réussie.');
          sessionStorage.removeItem('authToken');
          this.$router.push('/');
        } else {
          console.error('Échec de la déconnexion. Réponse de l\'API:', response.data);
        }
      } catch (error) {
        console.error('Erreur lors de la requête de déconnexion:', error);
      }
    },
  },
}
</script>

<style scoped>
/* Personnalisez le style de votre offcanvas ici, si nécessaire */
.offcanvas-header {
  background-color: #000000;
}

.offcanvas-body {
  background-color: #000000;
}

.btn-close {
  filter: invert(1);
  /* Pour rendre le bouton de fermeture blanc */
}

/* Définir la largeur de l'offcanvas à 50% de l'écran */
.offcanvas-half {
  width: 50% !important;
  max-width: none;
}
</style>