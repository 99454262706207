<template>
	<Header /><br />
	<section class="pt-0">
		<div class="bg-dark position-relative pt-8 pt-xl-9 pb-7 pb-sm-8 px-4 px-md-0 overflow-hidden">
			<figure class="position-absolute top-0 start-0">
				<svg class="opacity-2" width="1859" height="1775" viewBox="0 0 1859 1775" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g filter="url(#filter0_d_391_4)">
						<ellipse cx="929.5" cy="862.5" rx="754.5" ry="719.5" fill="url(#paint0_linear_391_4)"
							shape-rendering="crispEdges" />
					</g>
					<g filter="url(#filter1_d_391_4)">
						<ellipse cx="929.5" cy="863" rx="585.5" ry="558" fill="url(#paint1_linear_391_4)"
							shape-rendering="crispEdges" />
					</g>
					<g filter="url(#filter2_d_391_4)">
						<ellipse cx="929.5" cy="887.5" rx="909.329" ry="867.5" fill="url(#paint2_linear_391_4)"
							shape-rendering="crispEdges" />
					</g>
					<defs>
						<filter id="filter0_d_391_4" x="155" y="123" width="1549" height="1479"
							filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
							<feFlood flood-opacity="0" result="BackgroundImageFix" />
							<feColorMatrix in="SourceAlpha" type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset />
							<feGaussianBlur stdDeviation="10" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4" result="shape" />
						</filter>
						<filter id="filter1_d_391_4" x="324" y="285" width="1211" height="1156"
							filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
							<feFlood flood-opacity="0" result="BackgroundImageFix" />
							<feColorMatrix in="SourceAlpha" type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset />
							<feGaussianBlur stdDeviation="10" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4" result="shape" />
						</filter>
						<filter id="filter2_d_391_4" x="0.171387" y="0" width="1858.66" height="1775"
							filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
							<feFlood flood-opacity="0" result="BackgroundImageFix" />
							<feColorMatrix in="SourceAlpha" type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset />
							<feGaussianBlur stdDeviation="10" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_391_4" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_391_4" result="shape" />
						</filter>
						<linearGradient id="paint0_linear_391_4" x1="929.5" y1="143" x2="929.5" y2="1582"
							gradientUnits="userSpaceOnUse">
							<stop offset="0.0569271" stop-color="#D9D9D9" stop-opacity="0.09" />
							<stop offset="0.998202" stop-color="#D9D9D9" stop-opacity="0" />
						</linearGradient>
						<linearGradient id="paint1_linear_391_4" x1="929.5" y1="305" x2="929.5" y2="1421"
							gradientUnits="userSpaceOnUse">
							<stop offset="0.0569271" stop-color="#D9D9D9" stop-opacity="0.07" />
							<stop offset="0.998202" stop-color="#D9D9D9" stop-opacity="0" />
						</linearGradient>
						<linearGradient id="paint2_linear_391_4" x1="929.5" y1="20" x2="929.5" y2="1755"
							gradientUnits="userSpaceOnUse">
							<stop offset="0.0569271" stop-color="#D9D9D9" stop-opacity="0.1" />
							<stop offset="0.998202" stop-color="#D9D9D9" stop-opacity="0" />
						</linearGradient>
					</defs>
				</svg>
			</figure>
		</div>

		<div class="container mt-n9">
			<div>
				<div class="swiper-wrapper align-items-center pt-5">
					<div class="swiper-slide">
						<div class="card shadow border overflow-hidden p-0">
							<div class="card-header border-bottom p-4">
								<div class="icon-lg bg-light rounded-circle mb-3">
									<i class="bi bi-gem fa-lg lh-1 heading-color"></i>
								</div>

								<h6 class="mb-3">Freemium</h6>
								<p class="mb-0"> <span class="h2 mb-0 plan-price">0F</span></p>
							</div>

							<div class="card-body p-4">
								<div class="text-center text-lg-start">
									<h6>Les fonctionnalités</h6>
									<ul>
										<li class="list-group-item d-flex heading-color mb-0"><i
												class="bi bi-check-lg text-primary me-1"></i>Accès aux statistiques des
											badges</li><br />
										<li class="list-group-item d-flex heading-color mb-0"><i
												class="bi bi-check-lg text-primary me-1"></i>Obtenez 15 téléchargements
											du badge "J'y serai" pour les participants actuels ou futurs de votre
											événement.</li>
									</ul>
									<a href="/signup" class="btn btn-dark w-100">Commencer</a>
								</div>
							</div>
						</div>
					</div> <br /><br />
					<div class="swiper-slide">
						<div class="card border border-primary p-0">

							<div class="card-header border-bottom p-4">
								<div class="icon-lg bg-light rounded-circle mb-3">
									<i class="bi bi-lightning-charge-fill fa-lg lh-1 heading-color"></i>
								</div>

								<h6 class="mb-3">Premium</h6>
								<p class="mb-0"> <span class="h2 mb-0 plan-price" data-monthly-price="$35"
										data-annual-price="$30">5000F</span></p>
							</div>

							<div class="card-body p-4">
								<div class="text-center text-lg-start">
									<h6>Les fonctionnalités</h6>
									<ul>
										<li class="list-group-item d-flex heading-color mb-0"><i
												class="bi bi-check-lg text-primary me-1"></i>Accès aux statistiques des
											badges</li><br />
										<li class="list-group-item d-flex heading-color mb-0"><i
												class="bi bi-check-lg text-primary me-1"></i>Obtenez un nombre illimité
											de téléchargements du badge "J'y serai" pour les participants ou futurs
											participants de votre événement.</li>
									</ul>
									<a href="/signup" class="btn btn-primary w-100">Commencer</a>
								</div>
							</div>
						</div>
					</div> <br /><br />
					<div class="swiper-slide">
						<div class="card border border-primary p-0">
							<div class="card-header border-bottom p-4">
								<div class="icon-lg bg-light rounded-circle mb-3">
									<i class="bi bi-shield-check fa-lg lh-1 heading-color"></i>
								</div>

								<h6 class="mb-3">Standard</h6>
								<p class="mb-0"> <span class="h2 mb-0 plan-price" data-monthly-price="$45"
										data-annual-price="$40">3500F</span></p>
							</div>

							<div class="card-body p-4">
								<div class="text-center text-lg-start">
									<h6>Les fonctionnalités</h6>
									<ul>
										<li class="list-group-item d-flex heading-color mb-0"><i
												class="bi bi-check-lg text-primary me-1"></i>Accès aux statistiques des
											badges</li><br />
										<li class="list-group-item d-flex heading-color mb-0"><i
												class="bi bi-check-lg text-primary me-1"></i>Obtenez 125 téléchargements
											du badge "J'y serai" pour les participants ou futurs participants de votre
											événement.</li>
									</ul>
									<!-- Button -->
									<a href="/signup" class="btn btn-warning w-100">Commencer</a>
								</div>
							</div>
						</div>
					</div> <br /><br />
				</div>
			</div>
		</div>
	</section> <br /><br />

<!-- 

	<section class="pt-0">
		<div class="container">
			<div class="inner-container-small text-center mb-4 mb-sm-6">
				<h2 class="mb-4">Fonctionnalités incluses dans chaque plan</h2>
				<p class="mb-0">Notre passion pour l'excellence du service client est l'une des raisons pour lesquelles
					nous sommes leaders sur le marché. Nous avons toujours travaillé très dur pour offrir à nos clients
					la meilleure expérience.</p>
			</div>

			<div class="table-responsive-xl mt-5">
				<table class="table table-border align-middle">
					<thead class="align-top">
						<tr>
							<th scope="col"></th>

							<th scope="col">
								<div class="text-center p-3">
									<span class="mb-3 heading-color">Freemium</span>
									<p class="mb-3"> <span class="h2 mb-0 plan-price">0$</span></p>
									<a href="#" class="btn btn-sm btn-dark mb-0">Commencer</a>
								</div>
							</th>

							<th scope="col">
								<div class="text-center p-3">
									<span class="mb-3 heading-color">Standard</span>
									<p class="mb-3"> <span class="h2 mb-0 plan-price">4.59$</span></p>
									<a href="#" class="btn btn-sm btn-dark mb-0">Commencer</a>
								</div>
							</th>

							<th scope="col">
								<div class="text-center p-3">
									<span class="mb-3 heading-color">Premium</span>
									<p class="mb-3"> <span class="h2 mb-0 plan-price">8.59$</span></p>
									<a href="#" class="btn btn-sm btn-dark mb-0">Commencer</a>
								</div>
							</th>
						</tr>
					</thead>
					<tbody class="border-top-0">
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Espace de stockage</span>
							</th>
							<td class="text-center"> 40 Go </td>
							<td class="text-center"> 60 Go </td>
							<td class="text-center"> Illimité </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Connecté au cloud</span>
							</th>
							<td class="text-center"> Oui </td>
							<td class="text-center"> Oui </td>
							<td class="text-center"> Oui </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Outils de codage</span>
							</th>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Débogage avancé</span>
							</th>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>

						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Applications
									mobiles</span></th>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Outils Web</span></th>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Contrôle de
									version</span></th>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Sécurité</span></th>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>
						<tr>
							<th scope="row"><span class="fw-normal heading-color ps-lg-4 mb-0">Accès en équipe</span>
							</th>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-danger"> <i class="bi bi-x-circle fa-lg"></i> </td>
							<td class="text-center text-primary"> <i class="bi bi-check-circle fa-lg"></i> </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</section> <br /><br />

	<section class="position-relative z-index-2 pt-0">
		<div class="container position-relative">
			<div class="bg-dark rounded position-relative overflow-hidden p-4 p-sm-7">
				<figure class="position-absolute top-0 start-0 mt-n5">
					<svg class="fill-light" style="opacity:0.02" width="662" height="614" viewBox="0 0 662 614"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M-78 0V603.815C-61.4821 612.795 -44.1025 615.867 -28.4464 611.85C9.04192 602.16 38.9177 554.186 58.4519 503.612C77.8424 453.511 90.1949 397.029 105.995 343.383C121.794 289.973 142.477 237.745 173.215 206.549C224.779 154.321 291.425 172.991 349.166 202.768C406.907 232.545 466.227 272.248 525.979 256.414C570.505 244.598 611.441 200.878 636.002 138.724C652.233 97.6029 661.138 48.9196 662 0L-78 0Z">
						</path>
					</svg>
				</figure>

				<figure class="position-absolute top-0 end-0 mt-n8 me-n8">
					<svg class="opacity-3" width="371" height="354" viewBox="0 0 371 354" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<ellipse cx="172.5" cy="176.5" rx="131.5" ry="125.5" fill="url(#paint0_linear_195_2)"></ellipse>
						<ellipse cx="185.5" cy="177" rx="185.5" ry="177" fill="url(#paint1_linear_195_2)"></ellipse>
						<defs>
							<linearGradient id="paint0_linear_195_2" x1="172.5" y1="51" x2="172.5" y2="302"
								gradientUnits="userSpaceOnUse">
								<stop offset="0.0569271" stop-color="#D9D9D9" stop-opacity="0.5"></stop>
								<stop offset="0.998202" stop-color="#D9D9D9" stop-opacity="0"></stop>
							</linearGradient>
							<linearGradient id="paint1_linear_195_2" x1="185.5" y1="0" x2="185.5" y2="354"
								gradientUnits="userSpaceOnUse">
								<stop offset="0.0569271" stop-color="#D9D9D9" stop-opacity="0.2"></stop>
								<stop offset="0.998202" stop-color="#D9D9D9" stop-opacity="0"></stop>
							</linearGradient>
						</defs>
					</svg>
				</figure>
			</div>
		</div>
	</section> Button -->
</template>

<script>
import Header from '@/components/Header.vue'

export default {
	components: {
		Header,
	}
}
</script>

<style scoped>
/* Custom styles here */
</style>
