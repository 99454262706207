import { createRouter, createWebHistory } from "vue-router";
import Accueil from "../views/Accueil.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import Pricing from "../views/Pricing.vue";
import Blogs from "../views/Blogs.vue";
import RessetPassword from "../views/RessetPassword.vue";

import AllEvents from "../views/events/AllEvents.vue";
import AllBadges from "../views/badges/AllBadges.vue";
import GenerateBadge from "../views/badges/GenerateBadge.vue";
import Apercu from "../views/organisateur/Apercu.vue";
import MesTransactions from "../views/organisateur/MesTransactions.vue";
import MesBadges from "../views/organisateur/MesBadges.vue";
import MesEvenements from "../views/organisateur/MesEvenements.vue";
import Profile from "../views/organisateur/Profile.vue";
import Parametre from "../views/organisateur/Parametre.vue";
import SoumettreBadges from "../views/organisateur/SoumettreBadges.vue";
import WhatsappSms from "../views/organisateur/WhatsappSms.vue";

//ADMIN

import Dashboard from "../views/admin/Dashboard.vue";
import Blog from "../views/admin/Blog.vue";

import Swal from "sweetalert2";
import AllTicket from "@/views/ticket/AllTicket.vue";

const routes = [
  {
    path: "/",
    name: "accueil",
    component: Accueil,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/resset-password",
    name: "resset-password",
    component: RessetPassword,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    path: "/blogs",
    name: "blogs",
    component: Blogs,
  },
  {
    path: "/events/all-events",
    name: "all-events",
    component: AllEvents,
    meta: { requiresAuth: true },
  },
  {
    path: "/badges/all-badges",
    name: "all-badges",
    component: AllBadges,
  },
  {
    path: "/badges/generate-badges/:id",
    name: "generate-badges",
    component: GenerateBadge,
  },

  {
    path: "/ticket/all-tickets",
    name: "all-tickets",
    component: AllTicket,
  },

  {
    path: "/organisateur/apercu",
    name: "apercu",
    component: Apercu,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/mes-transactions",
    name: "mes-transactions",
    component: MesTransactions,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/mes-badges",
    name: "mes-badges",
    component: MesBadges,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/creer-badge",
    name: "creer-badge",
    component: SoumettreBadges,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/mes-evenements",
    name: "mes-evenements",
    component: MesEvenements,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/mon-profile",
    name: "mon-profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/parametre",
    name: "parametre",
    component: Parametre,
    meta: { requiresAuth: true },
  },
  {
    path: "/organisateur/whatsappsms",
    name: "whatsappsms",
    component: WhatsappSms,
    meta: { requiresAuth: true },
  },

  //ADMINISTRATEUR

  {
    path: "/admin/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },

  {
    path: "/admin/blog",
    name: "blog",
    component: Blog,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Vérification du jeton JWT dans le sessionStorage lors de la navigation
router.beforeEach((to, from, next) => {
  const authToken = sessionStorage.getItem("authToken");
  if (
    to.name !== "login" &&
    to.name !== "signup" &&
    !authToken &&
    !to.meta.requiresAuth
  ) {
    // Si l'utilisateur n'est pas connecté et qu'il essaie d'accéder à une page autre que la page de connexion ou d'inscription
    // et que la page ne nécessite pas d'authentification
    next();
  } else if (to.meta.requiresAuth && !authToken) {
    // Si la page nécessite une authentification mais l'utilisateur n'est pas connecté
    Swal.fire({
      icon: "error",
      text: "Vous devez vous connecter pour accéder à cette page.",
    });
    next({ name: "login" }); // Redirection vers la page de connexion
  } else {
    next(); // Continuer la navigation normalement
  }
});

export default router;
