<template>
    <Header />
    <br /><br /><br /><br />
    <section class="blog-details pt-60">
        <div class="container">
            <div class="row justify-content-between gap-1">
                <!-- Colonne pour afficher le badge et les contrôles -->
                <div class="col-lg-6">
                    <div class="blog-details-wrap">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 ps-md-1">
                                    <div id="badge-container">
                                        <canvas id="badge-canvas"></canvas>
                                    </div>
                                    <div class="slider-row my-2">
                                        <div class="container-fluid row">
                                            <div class="col-8 offset-4">
                                                <div class="range">
                                                    <label for="zoom-slider"></label>
                                                    <input type="range" id="zoom-slider" name="zoom" min="0.1" max="3"
                                                        step="0.01" value="1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="loader" class="loader"></div>
                                <div class="col-12 col-md-9 ps-md-1 d-flex justify-content-center align-items-center">
                                    <div class="slider-row">
                                        <input name="photo" type="file" id="photo-upload" accept="image/*"
                                            class="d-none" required />
                                        <div class="row justify-content-around">
                                            <label for="photo-upload"
                                                class="btn btn-sm btn-primary col-10 col-md-9 bg-primary border-primary text-white mb-2 mb-md-0">
                                                <i class="fa fa-image"></i> Votre image
                                            </label><span>&nbsp;</span>
                                            <input name="photo" type="file" id="photo-upload" accept="image/*"
                                                class="d-none" required />
                                            <button id="get-badge"
                                                class="btn btn-sm btn-danger col-10 col-md-9 border-danger bg-danger text-white"
                                                @click="handleDownload">
                                                <span v-if="isDownloading" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                                <span v-if="!isDownloading"><i class="fa fa-download"></i>
                                                    Télécharger</span>
                                                <span v-if="isDownloading"> En cours...</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-around mt-4">
                                    <div class="col-1 col-md-4">
                                        <button class="btn btn-sm btn-success" @click="shareOnWhatsApp">
                                            <i class="fab fa-whatsapp"></i>
                                        </button>
                                    </div>
                                    <div class="col-1 col-md-4">
                                        <button class="btn btn-sm btn-info" @click="shareOnTwitter">
                                            <i class="fab fa-twitter"></i>
                                        </button>
                                    </div>
                                    <div class="col-1 col-md-4">
                                        <button class="btn btn-sm btn-primary" @click="shareOnFacebook">
                                            <i class="fab fa-facebook"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br /><br />

                <!-- Colonne pour afficher la description de l'événement à droite -->
                <div class="col-lg-5">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">
                                <i class="fas fa-calendar-alt"></i> {{ event.name }}
                            </h5>
                            <p v-if="event.description" class="card-text">
                                <i class="fas fa-file-alt"></i> {{ event.description }}
                            </p>
                            <p v-else class="card-text">
                                <i class="fas fa-file-alt"></i> (Aucune description disponible)
                            </p>
                            <p class="card-text">
                                <i class="fas fa-map-marker-alt"></i> {{ event.location }}
                            </p>
                            <p class="card-text">
                                <i class="fas fa-calendar-day"></i> {{ formatDate(event.start_date) }} au
                            </p>
                            <p class="card-text">
                                <i class="fas fa-calendar-day"></i> {{ formatDate(event.end_date) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section><br /><br /><br /><br /><br />
    <!-- Footer-->
    <footer class="py-4 mt-auto" style="background-color: #000; color: white;">
        <div class="container px-5">
            <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                <div class="col-auto">
                    <div class="small m-0">Mougni 2024</div>
                </div>
                <div class="col-auto">
                    <div class="small m-0">Confidentialité</div>
                </div>
                <div class="col-auto">
                    <div class="small m-0">Terms et conditions</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/axios/axios.js";
import { v4 as uuidv4 } from 'uuid';
import { BACK_URL } from '@/event_badge.js';
import Swal from 'sweetalert2';

function createSlug(str) {
    return str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
}

export default {
    components: { Header },
    data() {
        return {
            BACK_URL: BACK_URL,
            isDownloading: false,
            img: new Image(),
            templateImg: new Image(),
            scale: 1,
            imgX: 0,
            imgY: 0,
            isDragging: false,
            lastX: 0,
            lastY: 0,
            event: {},
        };
    },
    mounted() {
        const badgeId = this.$route.params.id;

        axios.post(`/event_badges/${badgeId}/increment-views`)
            .then(response => {
                console.log(response.data.message);
            })
            .catch(error => {
                console.error('Erreur lors de l\'incrémentation des vues:', error);
            });

        this.setupCanvas();
        this.loadTemplateImage(badgeId);

        document.getElementById('photo-upload').addEventListener('change', this.handleFileUpload);
        document.getElementById('zoom-slider').addEventListener('input', this.handleZoom);
    },
    methods: {
        formatDate(dateTime) {
            if (!dateTime) return '';
            return dateTime.split(' ')[0]; // Récupère seulement la partie date (YYYY-MM-DD)
        },
        setupCanvas() {
            const canvas = document.getElementById('badge-canvas');
            const ctx = canvas.getContext('2d');
            const badgeContainer = document.getElementById('badge-container');

            canvas.width = badgeContainer.clientWidth * 2;
            canvas.height = badgeContainer.clientHeight * 2;
            canvas.style.width = `${badgeContainer.clientWidth}px`;
            canvas.style.height = `${badgeContainer.clientHeight}px`;
            ctx.scale(2, 2);

            canvas.addEventListener('mousedown', this.handleInteractionStart);
            canvas.addEventListener('mousemove', this.handleInteractionMove);
            canvas.addEventListener('mouseup', this.handleInteractionEnd);
            canvas.addEventListener('touchstart', this.handleInteractionStart);
            canvas.addEventListener('touchmove', this.handleInteractionMove);
            canvas.addEventListener('touchend', this.handleInteractionEnd);
        },
        loadTemplateImage(badgeId) {
            axios.get(`/event/${badgeId}`)
                .then(response => {
                    const event = response.data.event;
                    const badgeImagePath = `${this.BACK_URL}${event.badge}`;
                    this.templateImg.src = badgeImagePath;
                    this.templateImg.onload = this.drawImage;
                    this.event = event;
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des détails de l\'événement:', error);
                });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.img.src = e.target.result;
                this.img.onload = this.drawImage;
            };
            reader.readAsDataURL(file);
        },
        handleZoom(event) {
            this.scale = parseFloat(event.target.value);
            this.drawImage();
        },
        drawImage() {
            const canvas = document.getElementById('badge-canvas');
            const ctx = canvas.getContext('2d');

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const imgWidth = 350 * this.scale;
            const imgHeight = (imgWidth * this.img.height) / this.img.width;
            ctx.drawImage(this.img, this.imgX, this.imgY, imgWidth, imgHeight);
            ctx.drawImage(this.templateImg, 0, 0, canvas.width / 2, canvas.height / 2);
            
        },
        handleInteractionStart(event) {
            event.preventDefault();
            const canvas = document.getElementById('badge-canvas');
            const rect = canvas.getBoundingClientRect();
            const x = (event.clientX || event.touches[0].clientX) - rect.left;
            const y = (event.clientY || event.touches[0].clientY) - rect.top;

            this.isDragging = true;
            this.lastX = x;
            this.lastY = y;
        },
        handleInteractionMove(event) {
            event.preventDefault();
            if (this.isDragging) {
                const canvas = document.getElementById('badge-canvas');
                const rect = canvas.getBoundingClientRect();
                const x = (event.clientX || event.touches[0].clientX) - rect.left;
                const y = (event.clientY || event.touches[0].clientY) - rect.top;

                this.imgX += x - this.lastX;
                this.imgY += y - this.lastY;
                this.lastX = x;
                this.lastY = y;

                this.drawImage();
            }
        },
        handleInteractionEnd(event) {
            event.preventDefault();
            this.isDragging = false;
        },
        handleDownload() {
            this.isDownloading = true;
            const badgeId = this.$route.params.id;

            axios.get(`/event/${badgeId}`)
                .then(response => {
                    const event = response.data.event;
                    const title = event.name;
                    const slug = createSlug(title);
                    const uniqueCode = uuidv4();

                    let maxDownloadsAllowed = 0;
                    switch (event.option) {
                        case 'Premium':
                            maxDownloadsAllowed = Infinity;
                            break;
                        case 'Freemium':
                            maxDownloadsAllowed = 15;
                            break;
                        case 'Standard':
                            maxDownloadsAllowed = 125;
                            break;
                        default:
                            maxDownloadsAllowed = 0;
                            break;
                    }

                    if (event.downloads < maxDownloadsAllowed) {
                        const link = document.createElement('a');
                        link.download = `badge_${slug}_${uniqueCode}_by@Mougni.com.png`;
                        link.href = document.getElementById('badge-canvas').toDataURL();
                        link.click();

                        axios.post(`/event_badges/${event.id}/increment-downloads`)
                            .then(response => {
                                console.log(response.data.message);
                            })
                            .catch(error => {
                                console.error('Erreur lors de l\'incrémentation des téléchargements:', error);
                            });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Limite atteinte',
                            text: `Vous avez atteint la limite de téléchargements pour l'option "${event.option}". Veuillez contacter le promoteur de l'événement.`,
                        });
                    }
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des détails de l\'événement:', error);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        },
        shareOnWhatsApp() {
            const badgeId = this.$route.params.id;
            const url = `${window.location.origin}/badges/generate-badges/${badgeId}`;
            const text = encodeURIComponent(`Génère ton badge j'y serai via ce lien : ${url} pour montrer ton soutien à/au ${this.eventName}`);
            window.open(`https://wa.me/?text=${text}`, '_blank');
        },
        shareOnTwitter() {
            const badgeId = this.$route.params.id;
            const url = `${window.location.origin}/badges/generate-badges/${badgeId}`;
            const text = encodeURIComponent(`Génère ton badge j'y serai via ce lien : ${url} pour montrer ton soutien à/au ${this.eventName}`);
            window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, '_blank');
        },
        shareOnFacebook() {
            const badgeId = this.$route.params.id;
            const url = `${window.location.origin}/badges/generate-badges/${badgeId}`;
            const text = encodeURIComponent(`Génère ton badge j'y serai via ce lien : ${url} pour montrer ton soutien à/au ${this.eventName}`);
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`, '_blank');
        }
    }

};
</script>

<style>
#badge-container {
    position: relative;
    width: 29rem;
    height: 29rem;
}

#badge-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (max-width: 576px) {
    #badge-container {
        width: 20rem;
        height: 20rem;
    }
}
</style>

    