<template>
    <main class="main">
        <Header /><br /><br />

        <div class="container mt-4">
            <!-- Affichage des articles -->
            <div class="row">
                <div v-for="article in articles" :key="article.id" class="col-md-4 mb-4">
                    <div class="card">
                        <img v-if="article.cover_image" :src="BACK_URL + article.cover_image" class="card-img-top" alt="Image de couverture">
                        <div class="card-body">
                            <h5 class="card-title">{{ article.title }}</h5>
                        </div>
                        <div class="card-footer">
                            <a :href="`/articles/${article.id}`" class="btn btn-primary">Lire l'article</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <footer class="py-4 mt-auto" style="background-color: #000; color: white;">
        <div class="container px-5">
            <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                <div class="col-auto">
                    <div class="small m-0">Mougni 2024</div>
                </div>
                <div class="col-auto">
                    <div class="small m-0">Confidentialité</div>
                </div>
                <div class="col-auto">
                    <div class="small m-0">Terms et conditions</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import axios from "@/axios/axios.js";
import Header from "@/components/Header.vue";
import { BACK_URL } from '@/article_image.js';


export default {
    components: { Header },
    data() {
        return {
            BACK_URL: BACK_URL,
            articles: []
        };
    },
    mounted() {
        this.fetchArticles();
    },
    methods: {
        async fetchArticles(page = 1) {
            try {
                const response = await axios.get(`/articles?page=${page}`);
                this.articles = response.data.data;
                this.pagination.currentPage = response.data.current_page;
                this.pagination.totalPages = response.data.last_page;
            } catch (error) {
            }
        }
    }
};
</script>

<style scoped>
.card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-body {
    background-color: #f8f9fa;
}

.card-footer {
    background-color: #e9ecef;
    text-align: center;
}

.card-img-top {
    height: 200px;
    object-fit: cover;
}
</style>
