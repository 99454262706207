<template>
    <div>
        <HeaderDash />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-9 px-md-4 border-start">
                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Recherche</h5>
                                <div class="row mb-4">
                                    <div class="col-md-6">
                                        <label for="startDate">Date</label>
                                        <input v-model="startDate" @input="fetchTransactions" type="date" id="startDate"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-6">
                                        <label for="searchNumber">Numéro</label>
                                        <input v-model="numero" @input="fetchTransactions" type="text"
                                            class="form-control" id="searchNumber">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">Historiques des transactions</h5>
                                <div class="table-responsive">
                                    <table class="account-table table">
                                        <thead style="background-color: #000;">
                                            <tr>
                                                <th scope="col" style="color: white;">N°</th>
                                                <th scope="col" style="color: white;">DATE</th>
                                                <th scope="col" style="color: white;">EVENT</th>
                                                <th scope="col" style="color: white;">MONTANT</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderDash from "@/components/HeaderDash.vue";
import SideBar from "@/components/SideBar.vue";
import axios from "@/axios/axios.js";

export default {
    components: { HeaderDash, SideBar },

}

</script>

<style>

</style>